@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --foreground-rgb: 0, 0, 0;
  --background-start-rgb: 228, 228, 231;
  --background-end-rgb: 255, 255, 255;
}

@media (prefers-color-scheme: dark) {
  :root {
    --foreground-rgb: 255, 255, 255;
    --background-start-rgb: 0, 0, 0;
    --background-end-rgb: 0, 0, 0;
  }
}

body {
  color: rgb(var(--foreground-rgb));
  background: linear-gradient(
  to bottom,
  transparent,
  rgb(var(--background-end-rgb))
  )
  rgb(var(--background-start-rgb));
}

@layer base {
  li {
    @apply p-2;
  }
  a:link,
  a:visited {
    @apply text-secondary-500;
    text-decoration: none;
  }

  blockquote {
    @apply relative border-[5px] border-primary-300 my-8 mx-0 py-4 pl-4 pr-5 italic;

    &::before {
      @apply absolute -top-[5px] h-[5px] bg-white;

      left: 50%;
      width: 94%;
      content: "";
      margin-left: -47%;
    }

    &::after {
      @apply absolute -top-[5px] h-[5px] bg-white;

      left: 50%;
      width: 94%;
      content: "";
      margin-left: -47%;
      top: auto;
      bottom: -5px;
    }

    p {
      margin: 0;
    }
  }
}

@layer components {
  ::selection {
    @apply text-white bg-secondary-500;
    text-shadow: none;
  }

  .progress-bar {
    @apply z-40 bg-secondary-500 border border-secondary-500 top-16 fixed left-0 right-0 h-1;
    transform-origin: 0%;
  }

  .md-blog {
    @apply justify-center text-justify h-full py-12 px-4 md:py-10 bg-primary-100 rounded-xl my-4;

    li {
      @apply text-primary-700 leading-[1.8em] tracking-wider;
    }

    li>a {
      @apply p-2 text-secondary-500 font-medium hover:text-white hover:bg-secondary-500 hover:rounded hover:px-2 hover:py-1;
    }
    p>a,
    a {
      @apply px-2 text-secondary-500 font-medium hover:text-white hover:bg-secondary-500 hover:rounded hover:px-2 hover:py-1;
    }

    .img-link.a {
      @apply hover:bg-none ;
    }

    p {
      @apply mb-4 text-lg leading-[1.8em] tracking-wider font-light text-primary-800;
    }

    p > code {
      @apply text-sm text-red-600 py-[1px] px-1 bg-white;

      text-shadow: none;
      border-radius: 2px;
    }

    pre {
      @apply pt-6 pb-2 px-4 rounded overflow-auto text-sm mb-6 mt-3 bg-neutral-700 bg-no-repeat;

      background-image: url(/images/window-buttons.png);
      background-size: 44px 10px;
      background-position: 16px 14px;
    }

    h2 {
      @apply text-left font-medium md:text-3xl capitalize font-sans text-secondary-500 text-shadow-lg shadow-primary-300 mt-8 mb-4;
    }

    h3 {
      @apply text-left text-2xl font-medium text-secondary-500 capitalize font-sans text-shadow-lg shadow-primary-300 mt-8 mb-4;
    }

    h4 {
      @apply text-left text-xl font-medium text-secondary-500 capitalize font-sans text-shadow-lg shadow-primary-300 mb-6;
    }
    table {
      @apply mt-4 mb-6 pt-2 pb-6 text-sm border-b-2 border-secondary-500 text-left;
    }
    th {
      @apply border-b-2 border-secondary-500 py-2 px-4 text-xl;
    }
    tr {
      @apply hover:bg-secondary-400 hover:cursor-pointer hover:text-white;
    }
    tr:nth-child(even) {
      @apply bg-secondary-200 text-neutral-800;
    }
    td {
      @apply py-2 px-4;
    }
    p > img {
      @apply mx-auto;
    }
  }

  /** md-blog **/

  input.ads:before,
  input.ads:after {
    @apply absolute h-[2px] w-0 bg-white transition-all ease-in;

    content: '';
  }
}
@layer utilities {
  .text-balance {
    text-wrap: balance;
  }
  .debug-red {
    border: solid 1px red;
  }

  .debug-blue {
    border: solid 1px blue;
  }

  .debug-yellow {
    border: solid 1px yellow;
  }

  .debug-green {
    border: solid 1px green;
  }

  @import "./prism.css";

}
